.home {
  width: 100%;
  height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  width: 70%;
  display: flex;
  justify-content: space-around;
}

.header .image-container {
  height: 100%;
  border: 2px solid #beabcf;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  transform: skewX(4deg);
  overflow: hidden;

  /* z-index: 8; */
}

.header img {
  height: 500px;
  width: 200px;
  background-color: var(--text-color);
  filter: drop-shadow(-1px -2px 30px #360068) blur(0.5px);
  position: relative;
  z-index: 10;
  animation: glow-blink 4s linear 0s infinite;
}

.image-container:hover::after {
  filter: drop-shadow(0px 0px 20px var(--text-color));
}

@keyframes glow-blink {
  0% {
    filter: drop-shadow(0px 0px 0px var(--text-color));
  }
  50% {
    filter: drop-shadow(0px 0px 20px var(--text-color));
  }
  100% {
    filter: drop-shadow(0px 0px 0px var(--text-color));
  }
}

.content {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.role-content p:first-child {
  margin-top: 1rem;
}

span.special {
  display: inline-block;
  font-size: 2.5rem;
  color: var(--text-color);
  text-decoration: solid underline #ac71e3;
  filter: drop-shadow(0px 0px 1.5px red);
  animation: glow-blink-text 2s linear 0s infinite;
}

@keyframes glow-blink-text {
  0% {
    filter: drop-shadow(0px 0px 0px #ff0000);
  }
  50% {
    filter: drop-shadow(0px 0px 2px #ff0000);
  }
  100% {
    filter: drop-shadow(0px 0px 0px #ff0000);
  }
}

.social-icons {
  margin: 1rem 0rem;
  display: flex;
}

.social-link {
  display: inline-block;
  margin: 1rem;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.social-link svg path {
  transition: 0.5s;
}

.social-link:hover svg path {
  fill: var(--text-color);
  filter: drop-shadow(0px 0px 2px #ff0000);
}

.social-link:hover {
  scale: 1.2;
  border: var(--text-color) solid 2px;
}

.btn {
  text-decoration: none;
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  border: 1px solid #fff;
  border-radius: 20px;
  font-weight: bolder;
  filter: drop-shadow(0px 0px 2px var(--text-color));
  transition: 0.5s;
}

.btn:hover {
  border: 1px solid var(--text-color);
  color: var(--text-color);
}

.fa-download {
  color: var(--text-color) !important;
}

.scroll-icon {
  position: absolute;
  bottom: 3rem;
  animation: upDown 2.5s ease-in 0s infinite forwards;
}
.scroll-icon a {
  width: 25px;
  height: 40px;
  border: 1px solid var(--text-color);
  box-shadow: 1px 2px 5px #7f20ec;
  /* background-color: red; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes upDown {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(40%);
  }
  100% {
    transform: translateY(0%);
  }
}

@media screen and (max-width: 1024px) {
  .header .image-container {
    margin: 2rem 0rem;
  }
}

@media screen and (max-width: 724px) {
  .home {
    height: fit-content;
  }

  .header {
    margin: 2rem 0rem;
    height: fit-content;
    flex-direction: column;
  }

  .scroll-icon {
    display: none;
  }
}
