.image-placeholder {
  width: 200px;
  height: 500px;
  background-color: var(--text-color);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.image-placeholder::before {
  content: "";
  position: absolute;
  left: 0%;
  background: rgb(181, 129, 230);
  background: linear-gradient(
    90deg,
    rgba(181, 129, 230, 0) 0%,
    rgba(181, 129, 230, 0.5) 50%,
    rgba(181, 129, 230, 0) 100%
  );
  box-shadow: 0px 0px 20px #b581e688;
  opacity: 0.5;
  width: 50px;
  height: 100%;
  animation: shine 1s ease infinite forwards;
}

@keyframes shine {
  to {
    left: 100%;
  }
}

img.image-visible {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

img.image-invisible {
  display: none;
}

@keyframes loading {
}
