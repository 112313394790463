.footer {
  width: 100%;
  background-color: var(--bg-primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-container {
  margin-top: 2rem;
  width: 70%;
  height: fit-content;
  display: flex;
  align-items: center;
}

.menu-container {
  display: flex;
  width: 60%;
}

.menu {
  width: 200px;
}

.menu h4 {
  margin-bottom: 1rem;
}

.menu .menu-item {
  list-style: none;
  text-indent: 1.5rem;
  opacity: 0.7;
  transition: 0.5s;
  cursor: pointer;
}

.menu-item:hover {
  opacity: 1;
}

.menu-item .menu-link {
  text-decoration: none;
}

.contact-form {
  width: 40%;
  border-left: 2px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form form {
  width: 80%;
  text-align: center;
}

.form-field {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-field label {
  text-align: left;
  margin: 0.5rem 0rem;
}

.form-field .input {
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: #d9d9d988;
  font-size: 1rem;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
}

.input::-webkit-scrollbar {
  width: 5px;
  background-color: red;
  border-radius: 20px;
  position: relative;
}

.form-field .input:hover,
.input:focus {
  background-color: #d9d9d944;
}

.form-field textarea {
  resize: none;
}

.contact-form button {
  color: #fff;
  background-color: var(--bg-primary-color);
}

.copy-right {
  margin: 1rem 0rem;
}

@media screen and (max-width: 724px) {
  .footer-container {
    flex-direction: column-reverse;
  }

  .contact-form {
    border: none;
    margin: 4rem 0rem;
    width: 100%;
  }
}
