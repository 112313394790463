@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.cdnfonts.com/css/a-authorized-signature");

:root {
  --bg-color: #070110;
  --bg-primary-color: #110225;
  --bg-transparent-color: #11022560;
  --text-color: #8400ff;
  --text-color-primary: #fff; 
}

* {
  font-family: "Poppins", sans-serif;
  color: var(--text-color-primary);
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  background-color: var(--bg-color);
  user-select: none;
  position: relative;
}

.beam-background {
  width: 100%;
  height: 200vh;
  position: absolute;
  z-index: -1;
}

.px-6 {
  position: absolute !important;
  top: 0;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(200, 200, 200, 0.3);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: var(--bg-primary-color);
  border-radius: 20px;
}

.project-card-container {
  margin: 0rem auto;
  width: 70%;
  display: grid;
  grid-template: minmax(500px, 700px) minmax(500px, 700px) / 1fr 1fr;
}

.project-h1 {
  margin: 3rem 0rem;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .project-card-container {
    grid-template: minmax(500px, 700px) minmax(500px, 700px) / 1fr;
  }
}
