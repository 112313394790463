.quick-intro {
  width: 100%;
  /* height: 500px; */
  background-color: var(--bg-primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.quick-intro h1 {
  margin-top: 2rem;
}

.quick-intro .container {
  width: 70%;
  height: 80%;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quick-intro .content {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.quick-intro .content p {
  text-align: justify;
  margin: 1rem 0rem;
}

.quick-intro .image-container {
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quick-intro .image-container img {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: #070110;
  border-radius: 50%;
}

@media screen and (max-width: 724px) {
  .quick-intro .container {
    flex-direction: column-reverse;
    height: fit-content;
  }
  .quick-intro .content {
    width: 90%;
    text-indent: 2rem;
  }

  .quick-intro .image-container {
    width: 90%;
  }
}
