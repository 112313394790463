.navbar {
  color: #fff;
  width: 100%;
  background-color: var(--bg-primary-color);
  text-align: end;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: 0.5s;
}

.navbar.visible {
  transform: translateY(0%);
}

.navbar.visible-none {
  transform: translateY(-100%);
}

.navbar.transparent {
  background-color: var(--bg-transparent-color) !important;
}

.navbar-container {
  margin: auto;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.navbar-container svg {
  width: 150px;
}

.item-list {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style-type: none;
}

.item-list-item {
  padding: 2rem 0.7rem;
  font-size: 1rem;
  font-weight: bolder;
}

.item-list-item-link {
  text-decoration: none;
  color: #fff;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex !important;
}

.d-height {
  height: 500px !important;
}

.toggle {
  display: none;
  position: absolute;
  top: 50%;
  right: 0px;
  cursor: pointer;
  transform: translateY(-50%);
}

@media screen and (max-width: 724px) {
  .item-list {
    /* display: none; */
    height: 00px;
    flex-direction: column;
    transition: 0.5s;
    overflow: hidden;
  }

  .navbar-container {
    width: 80%;
    flex-direction: column;
    align-items: start;
  }

  .item-list {
    width: 100%;
    align-items: start;
  }

  .item-list-item {
    padding: 1rem 1rem;
    width: 100%;
    text-align: left;
    transition: 0.5s;
  }

  .item-list-item:hover {
    border-radius: 20px;
    background-color: #7d7b806c;
  }

  .toggle {
    display: flex;
  }

  .toggle svg {
    width: 40px;
  }

  .down {
    top: 95%;
  }
}
