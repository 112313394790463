.time-line-container {
  margin: auto;
  width: 80%;
}
.time-line-container > h1 {
  margin: 5rem 0rem;
  text-align: center;
  text-transform: uppercase;
}
.timeline-card {
  margin: 2rem 0rem;
  padding: 1rem;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.timeline-card .logo {
  height: 200px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 20px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 10px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-card .details-card {
  width: 80%;
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  overflow: hidden;
}

.college-details {
  padding: 1rem;
  width: 100%;
  background-color: var(--bg-primary-color);
  position: relative;
}

.college-details h4 {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.details-list {
  padding: 2rem;
}

@media screen and (max-width: 724px) {
  .time-line-container {
    width: 100%;
  }
  .timeline-card {
    flex-direction: column;
    width: 100%;
  }
  .timeline-card .logo {
    height: 150px;
    margin: 1rem;
  }
  .timeline-card .details-card {
    width: 100%;
  }
  .college-details h4 {
    position: static;
    transform: none;
  }
}
