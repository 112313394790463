.skillbar-container {
  width: 100%;
  margin: 5rem 0rem;
  text-align: center;
}

.skillbar-container > h1 {
  margin-bottom: 5rem;
}

.skillbar {
  margin: 2rem auto;
  width: 40%;
  display: flex;
  align-items: center;
}

.skillbar .skill-image-container {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skillbar .skill-image-container img {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 0px 5px var(--text-color));
}

.skillbar .skill-content {
  margin-left: 1rem;
  width: 95%;
  height: 68px;
  display: flex;
  align-items: center;
  background-color: var(--bg-transparent-color);
  border-radius: 10px;
  text-align: left;
}

.skill-content > h3 {
  margin-left: 1rem;
}
