.skills-home {
  width: 100%;
  margin-bottom: 5rem;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-home h1 {
  text-transform: uppercase;
}

.skills-home .card {
  margin: 3rem 0rem;
  background-color: var(--bg-primary-color);
  width: 70%;
  aspect-ratio: 2/1;
  border-radius: 5px;
  display: flex;
  position: relative;
}

.card.rev {
  flex-direction: row-reverse;
}

.card h2 {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
}

.card .svg-container {
  margin-top: 2rem;
  width: 50%;
}

.card.rev .svg-container {
  margin-top: 2rem;
}

.card .svg-container svg {
  width: 100%;
  height: 450px;
}

.card .svg-container .backend-svg {
  margin-left: 2rem;
  width: 90%;
}

.card .skills-container {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card .skills-container h2 {
  margin-bottom: 3rem;
}

.card .skills-container .skills-icon-container {
  width: 100%;
  height: 200px;
  display: grid;
  grid-template: 1fr 1fr/ 1fr 1fr 1fr 1fr;
}

.skills-icon-container .skill-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skill-image img {
  width: 68px;
  height: 68px;
}

@media screen and (max-width: 1024px) {
  .card {
    width: 80% !important;
  }
}

@media screen and (max-width: 724px) {
  .card,
  .card.rev {
    margin-bottom: 15rem !important;
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }

  .card h2 {
    text-align: center;
  }

  .card .svg-container {
    width: 80%;
    height: 50%;
  }

  .card .skills-container {
    width: 80%;
    margin-bottom: 2rem;
  }

  .skill-image img {
    width: 48px;
    height: 48px;
  }
}
