.project-card {
  margin: 1rem;
  /* border: 1px solid #fff; */
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-card .project-image {
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.project-image img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: 0.5s;
}

.project-card:hover .project-image img {
  scale: 1.1;
}

.project-card .project-details {
  width: 100%;
  padding: 0rem 1rem;
}

.short-description ul {
  margin-top: 1rem;
  list-style: none;
}

.short-description ul li {
  margin: 4px 0px;
}

.short-description ul li a {
  color: var(--text-color);
}
